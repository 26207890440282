import { FunctionComponent } from 'react';

import SectionArticles from 'components/content/sections/Articles';
import { ArticleType } from 'components/content/sections/Articles/types';
import LayoutContainer, { Sizes } from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const SectionBlogPosts: FunctionComponent<Props> = ({ translations, articles }) => {
    return (
        <StyledComponent className="section-blog-posts">
            <LayoutContainer size={Sizes.Medium}>
                <SectionArticles
                    articleType={ArticleType.Triple}
                    articles={articles}
                    translations={translations}
                    visibleMoreArticlesButton
                />
            </LayoutContainer>
        </StyledComponent>
    );
};

export default SectionBlogPosts;
