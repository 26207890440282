import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    padding: 8em 0;
    background-color: ${vars.backgroundWhite};

    .headline {
        color: ${vars.colorSecondary};
        font-size: 4.2em;
        text-align: center;
        line-height: 1.1;
    }

    .subHeadline {
        color: ${vars.colorSelectHover};
        font-size: 4.2em;
        text-align: center;
        line-height: 1.1;
        margin-bottom: 0.5em;
    }

    .link-tag {
        .layout-button {
            .button {
                padding: 1.4em 3.6em;
                font-size: 1.2em;

                @media (pointer: fine) {
                    &:hover {
                        filter: brightness(120%);
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.tabletS}) {
        padding: 6em 0;
    }

    @media all and (max-width: ${vars.mobileM}) {
        .headline {
            font-size: 3.9em;
        }

        .subHeadline {
            font-size: 3.9em;
        }
    }
`;
