import { ReactNode } from 'react';

export enum PageSlugs {
    Home = 'home',
    Partners = 'partners',

    Blog = 'blog',
    Article = 'blog/[articleSlug]',

    CustomerRegister = 'customerRegister',
    CustomerLogin = 'customerLogin',
    CustomerActivate = 'customerActivate',
    CustomerPasswordReset = 'customerPasswordReset',
    CustomerPasswordChange = 'customerPasswordChange',

    PartnerRegister = 'partnerRegister',
    PartnerLogin = 'partnerLogin',
    PartnerActivate = 'partnerActivate',
    PartnerPasswordReset = 'partnerPasswordReset',
    PartnerPasswordChange = 'partnerPasswordChange',

    OrderSummary = 'orderSummary',

    Courses = 'courses',

    Certificates = 'certificates',

    Regulations = 'regulations',
    Contact = 'contact',
}

export interface PageSection {
    slug: string;
    order: number;
    isVisible: boolean;
    enabled?: boolean;
    contentTable?: boolean;
    children: (slug: string, translations: Record<string, any>) => ReactNode;
}
