import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;

    [class$=container] {
        width: 100%;

        [class$=control] {
            width: 100%;
            height: 3em;
            border: .1em solid white;
            padding: 0 1em;
            color: ${vars.colorTextDark};
            border-radius: .3em;
            font-size: .9em;

            [class$=placeholder] {
                color: ${vars.colorTextDark};
            }
        }

        [class$=menu] {
            z-index: 99;
        }
    }

    &.style-primary {}

    &.style-secondary {
        [class$=container] {
            width: 100%;

            [class$=control] {
                background: transparent;
                border: .01em solid white;
                box-shadow: none;


                [class$=ValueContainer] {
                    [class$=singleValue] {
                        color: white;
                    }
                }

                [class$=singleValue] {
                    color: white;
                }

                [class$=IndicatorsContainer] {
                    [class$=indicatorSeparator] {
                        background-color: white;
                    }
                    [class$=indicatorContainer] {
                        color: white;
                    }
                }

                [class$=placeholder] {
                    color: white;
                }
            }

            [class$=menu] {
                z-index: 99;
                background-color: rgba(255, 255, 255, 0.2);

                [class$=MenuList] {
                    [class$=option] {
                        color: white;
                    }
                }
            }
        }
    }

    &.style-third {
        .internal-wrapper {
            border: none;
        }
        
        [class$=container] {
            width: 100%;

            [class$=control] {
                border: none;
                box-shadow: none;
                height: 50px;

                [class$=ValueContainer] {
                    [class$=singleValue] {
                        border-radius: 10px;
                    }
                }

                [class$=singleValue] {
                    border-radius: 10px;
                }

                [class$=IndicatorsContainer] {
                    [class$=indicatorSeparator] {
                        display: none;
                    }
                    [class$=indicatorContainer] {
                        color: #000000;
                        padding: 0;
                    }
                }

                [class$=placeholder] {
                    color: ${vars.colorTextGray};
                }
            }

            [class$=menu] {
                z-index: 99;
                padding-top: 10px;
                border-radius: 10px;
            }
        }
    }
`;
