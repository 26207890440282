const translations = {
    headline: {
        type: 'text',
        defaultValue: 'CHCESZ MIEĆ NAJŚWIEŻSZE INFORMACJE?',
    },
    subHeadline: {
        type: 'text',
        defaultValue: 'Dołącz do naszego newslettera, by otrzymywać nową wiedzę jako pierwszy!',
    },
    formAgreement: {
        type: 'text',
        defaultValue: 'Chcę otrzymywać e-maile z aktualizacjami i akceptuję',
    },
    formAgreementSpan: {
        type: 'text',
        defaultValue: 'POLITYKĘ PRYWATNOŚCI',
    },
    formAgreementConjunction: {
        type: 'text',
        defaultValue: 'oraz',
    },
    formAgreementSecondSpan: {
        type: 'text',
        defaultValue: 'REGULAMIN',
    },
    formWrongEmail: {
        type: 'text',
        defaultValue: 'Email jest niepoprawny',
    },
    formSubmit: {
        type: 'text',
        defaultValue: 'Zapisz się',
    },
    alreadyScheduledButton: {
        type: 'text',
        defaultValue: 'Jesteś zapisany',
    },
};

export default translations;
