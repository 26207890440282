import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    background-color: ${vars.backgroundLightGray};

    .layout-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
            max-width: 270px;

            .headline {
                color: ${vars.colorSecondary};
                font-size: 2.1em;
                line-height: 1.2;
                margin-bottom: 0.4em;
            }

            .subHeadline {
                color: ${vars.colorSecondary};
                font-size: 0.9em;
            }
        }

        .right {
            max-width: 480px;

            .form {
                display: flex;
                flex-direction: column;
                gap: 1.4em;

                .input {
                    width: 100%;
                    padding: 1em 1.8em;
                    border-radius: 5px;
                    outline: none;
                    border: none;
                    transition: all 100ms ease;

                    &:focus {
                        box-shadow: rgba(0, 0, 0, 0.04) 0 3px 5px;
                    }
                }

                .submit-container {
                    display: flex;
                    justify-content: space-between;
                    gap: 2em;

                    .agreement {
                        cursor: pointer;
                        display: flex;
                        gap: 1em;
                        align-items: center;

                        &__box {
                            position: relative;
                            width: 26px;
                            height: 26px;
                            min-width: 26px;
                            border-radius: 5px;
                            border: 1px solid ${vars.colorSecondary};
                            background-color: ${vars.backgroundWhite};
                            transition: border-color 200ms ease;

                            &:after {
                                content: '';
                                position: absolute;
                                background-color: ${vars.backgroundLabel};
                                inset: 0;
                                border-radius: 5px;
                                transform: scale(0.1);
                                opacity: 0;
                                transition: opacity 200ms ease, transform 200ms ease;
                            }

                            &.checked {
                                &:after {
                                    opacity: 1;
                                    transform: scale(0.8);
                                }
                            }
                        }

                        &__text {
                            max-width: 260px;
                            font-size: 14px;

                            &::selection {
                                background-color: transparent;
                            }

                            .layout-link {
                                display: inline-flex;
                                margin-left: 4px;

                                .link-tag {
                                    color: ${vars.colorSelectHover};
                                    font-weight: bold;

                                    &::selection {
                                        background-color: transparent;
                                    }

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }

                    .submit-button {
                        &.disable {
                            opacity: 0.6;
                            filter: grayscale(1);
                            pointer-events: none;
                        }

                        .layout-button {
                            .button {
                                &:hover {
                                    filter: brightness(120%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    @media all and (max-width: ${vars.tabletS}) {
        padding: 6em 0;

        .layout-container {
            flex-direction: column;
            gap: 2em;

            .right {
                .form {
                    .submit-container {
                        flex-direction: column;

                        .layout-button {
                            width: 100%;

                            .button {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
`;
