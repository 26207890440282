import { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Option } from 'types/options';
import { Routes } from 'types/routes';

import Course from 'models/Course';
import CourseCategory from 'models/CourseCategory';

import { getOptionValue } from 'utils/option';

import Input from 'components/layout/forms/Input';
import { InputStyles } from 'components/layout/forms/Input/types';
import Select, { InputStyles as SelectStyles } from 'components/layout/forms/Select';
import LayoutContainer, { Sizes } from 'components/layout/LayoutContainer';
import useIsFirstRender from 'hooks/useIsFirstRender';

import StyledComponent from './styles';
import { Props } from './types';

interface HandleValue {
    [key: string]: string | number | Option<any>;
}

interface FormState {
    search: string;
    category: Option<CourseCategory>;
}

const SectionHero: FunctionComponent<Props> = ({ translations, courseCategories }) => {
    const router = useRouter();
    const [formValues, setFormValues]: [FormState, Function] = useState();
    const categoriesOptions = courseCategories?.elements?.map(category => ({ label: category?.name, value: category }));

    const isFirstRender = useIsFirstRender();

    const handleChange = (value: HandleValue) => setFormValues((prevState: FormState) => ({ ...prevState, ...value }));

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onSubmit = (event: FormEvent<HTMLFormElement> = null, page: number = 1) => {
        event?.preventDefault();

        let params = {};

        if (formValues?.search) params = { ...params, search: formValues.search };
        if (formValues?.category) params = { ...params, categoryId: getOptionValue(formValues.category)?.id };

        router.push({
            pathname: Routes.PublicCourses,
            query: params,
        });
    };

    useEffect(() => {
        let timeOut: NodeJS.Timeout;

        if (!isFirstRender && formValues) timeOut = setTimeout(() => {
            onSubmit(null);
        }, 500);

        return () => clearTimeout(timeOut);
    }, [formValues]);

    return (
        <StyledComponent className="section-hero">
            <div className="background">
                <img
                    src="images/home/bg-1280x720.png"
                    srcSet="images/home/bg-1280x720.png 600w, images/home/bg-1920x1080.png 800w"
                    alt="background"
                />
            </div>
            <LayoutContainer size={Sizes.Small}>
                <div className="section-hero__content">
                    <div className="copywriting">
                        <h1 className="headline">
                            {translations?.headline}
                        </h1>
                        <p className="subheadline">
                            {translations?.subheadline}
                        </p>
                    </div>
                    <div className="search-container">
                        <Input
                            name="search"
                            type="text"
                            className="input-search"
                            placeholder={'Jakiego kierunku rozwoju szukasz?'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange({ search: e.target.value })}
                            value={formValues?.search}
                            style={InputStyles.Secondary}
                            decoratorRight={{
                                visible: true,
                                children: (
                                    <FontAwesomeIcon
                                        icon={faMagnifyingGlass}
                                        style={{ color: '#4B4B4B' }}
                                    />
                                ),
                            }}
                        />
                        <Select
                            name="category"
                            onChange={(value: Option<Course>) => handleChange({ category: value })}
                            placeholder={'Wszystkie kategorie'}
                            options={categoriesOptions}
                            style={SelectStyles.Third}
                            value={formValues?.category}
                        />
                    </div>
                    <div className="feature-container">
                        <div className="headline">
                            {translations?.featureContainer?.headline}
                        </div>
                        <div className="cards">
                            {translations?.featureContainer?.list && translations?.featureContainer.list.map((listEl) => (
                                <div
                                    key={listEl.title}
                                    className="cards__card"
                                >
                                    <div className="cards__card__title">
                                        {listEl.title}
                                    </div>
                                    <div className="cards__card__description">
                                        {listEl.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default SectionHero;
