const translations = {
    headline: {
        type: 'text',
        defaultValue: 'Polecane szkolenia i warsztaty:',
    },
    inputSearch: {
        type: 'text',
        defaultValue: 'Jakiego kierunku rozwoju szukasz?',
    },
    selectCategories: {
        type: 'text',
        defaultValue: 'Wszystkie kategorie',
    },
    courseCtaButton: {
        type: 'text',
        defaultValue: 'Zapisz się',
    },
    alreadyScheduledButton: {
        type: 'text',
        defaultValue: 'Jesteś zapisany',
    },
};

export default translations;
