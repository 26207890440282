import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    padding: 8em 0 6em;

    .background {
        position: fixed;
        object-fit: cover;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: -1;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .layout-container {
        height: 100%;
        position: relative;

        .section-hero__content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8em;

            .copywriting {
                display: flex;
                flex-direction: column;
                max-width: 760px;
                text-align: center;
                gap: 2.4em;

                .headline {
                    font-size: 4.6em;
                    line-height: 1.2;
                    color: ${vars.colorTextWhite};
                }

                .subheadline {
                    font-size: 1.1em;
                    width: 80%;
                    line-height: 1.4;
                    margin: auto;
                    color: ${vars.colorTextWhite};
                }
            }

            .search-container {
                display: flex;
                gap: 2em;

                .name-search {
                    width: 600px;
                }

                .name-category {
                    width: 388px;
                }
            }

            .feature-container {
                text-align: center;
                display: flex;
                flex-direction: column;
                gap: 4em;

                .headline {
                    color: ${vars.colorTextWhite};
                    font-size: 1.5em;
                }

                .cards {
                    display: flex;

                    &__card {
                        position: relative;
                        flex: 1 1 100%;
                        color: ${vars.colorTextWhite};
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        gap: 1.8em;
                        padding: 0 2em;

                        &:last-of-type {
                            &::after {
                                content: none;
                            }
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            width: 1px;
                            height: 100%;
                            right: 0;
                            background-color: ${vars.colorTextWhite};
                        }

                        &__title {
                            font-size: 1.4em;
                        }

                        &__description {
                            font-size: 0.9em;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.tabletL}) {
        .layout-container {
            .section-hero__content {
                .search-container {
                    flex-direction: column;
                    align-items: center;
                    max-width: 380px;

                }
            }
        }
    }

    @media all and (max-width: ${vars.tabletS}) {
        .layout-container {
            .section-hero__content {
                .search-container {
                    flex-direction: column;
                    align-items: center;
                }

                .feature-container {
                    .cards {
                        flex-direction: column;
                        gap: 4em;

                        &__card {
                            max-width: 400px;
                            margin: auto;

                            &:after {
                                width: 100%;
                                height: 1px;
                                bottom: -2em;
                            }
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        .layout-container {
            .section-hero__content {
                .copywriting {
                    .headline {
                        font-size: 3.8em;
                    }
                }
            }

            .section-hero__content {
                .search-container {
                    width: 100%;
                    max-width: 100%;

                    .layout-forms-input {
                        width: 100%;

                        .input {
                            &::placeholder {
                                font-size: 0.8em;
                            }
                        }
                    }

                    .layout-forms-select {
                        max-width: 100%;
                    }
                }
            }
        }
    }
`;
