import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    padding: 8em 0;

    .headline {
        color: ${vars.colorTextWhite};
        font-size: 3.5em;
        text-align: center;
        line-height: 1.1;
        margin-bottom: 0.4em;
    }

    .subHeadline {
        color: ${vars.colorTextWhite};
        font-size: 1.5em;
        text-align: center;
        margin-bottom: 3em;

        h3 {
            font-family: ${vars.fontPrimary};
        }
    }

    .blog-posts-list {
        display: flex;
        gap: 2em;

        &__left {
            flex: 1 1 100%;

            .post-card {
                display: flex;
                flex-direction: column;
                border-radius: 5px;
                overflow: hidden;
                height: 100%;

                &__image {
                    height: 100%;

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &__content {
                    padding: 4em;
                    background-color: ${vars.backgroundWhite};
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;;
                    gap: 1em;

                    .date-category {
                        display: flex;
                        align-items: center;
                        font-size: 0.9em;

                        .date {
                            margin-right: 6px;
                            padding-right: 6px;
                            border-right: 2px solid ${vars.colorSecondary};
                            font-weight: 700;
                        }

                        .category {
                            color: ${vars.colorSelectHover};
                            font-weight: 700;
                        }
                    }

                    .title {
                        font-size: 1.5em;
                        line-height: 1.2;
                        font-weight: 700;
                    }

                    .learn-more {
                        display: flex;
                        margin-top: auto;

                        .button {
                            font-size: 1.5em;
                            color: ${vars.colorTertiary};
                        }
                    }
                }
            }
        }

        &__right {
            display: flex;
            flex: 1 1 100%;
            flex-direction: column;
            gap: 2em;

            .post-card {
                border-radius: 5px;
                overflow: hidden;
                display: flex;

                &:nth-of-type(2) {
                    .post-card__image {
                        order: 2;
                    }
                }

                &__image {
                    flex: 1 1 100%;
                    height: 100%;

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &__content {
                    flex: 1 1 calc(100% - 4em);
                    padding: 2em;
                    background-color: ${vars.backgroundWhite};
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 1em;

                    .date-category {
                        display: flex;
                        align-items: center;
                        font-size: 0.9em;

                        .date {
                            margin-right: 6px;
                            padding-right: 6px;
                            border-right: 2px solid ${vars.colorSecondary};
                            font-weight: 700;
                        }

                        .category {
                            color: ${vars.colorSelectHover};
                            font-weight: 700;
                        }
                    }

                    .title {
                        font-size: 1.5em;
                        line-height: 1.2;
                        font-weight: 700;
                    }

                    .learn-more {
                        display: flex;
                        margin-top: auto;

                        .button {
                            font-size: 1.5em;
                            color: ${vars.colorTertiary};
                        }
                    }
                }
            }
        }
    }

    .cta-more-courses {
        margin-top: 2.4em;
    }

    @media all and (max-width: ${vars.tabletL}) {
        padding: 6em 0;

        .blog-posts-list {
            flex-direction: column;
            max-width: 600px;
            margin: auto;

            &__left {
                .post-card {
                    flex-direction: row-reverse;
                    align-items: stretch;

                    &__image {
                        flex: 1 1 100%;
                        height: auto;

                        img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    &__content {
                        flex: 1 1 calc(100% - 4em);
                        padding: 4em 2em;
                        background-color: #FFFFFF;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.mobileM}) {
        .blog-posts-list {
            &__left {
                .post-card {
                    &__content {
                        padding: 4em 2em;
                    }
                }
            }

            &__right {
                .post-card {
                    &__image {
                        height: auto;
                    }
                }
            }
        }
    }
`;
