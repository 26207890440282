import { Option } from 'types/options';

import { Props as InputWrapperProps } from 'components/layout/forms/InputWrapper/types';

import Component from './component';

export interface Props extends InputWrapperProps {
    name: string;
    placeholder?: string;
    value?: any;
    onChange: (option: Option<any>) => any;
    disabled?: boolean;
    style?: InputStyles;
    styles?: object;
    options: Option<any>[];
    inputProps?: any;
}

export enum InputStyles {
    Primary = 'primary',
    Secondary = 'secondary',
    Third = 'third',
}

Component.defaultProps = {
    placeholder: null,
    value: '',
    style: InputStyles.Primary,
    styles: {},
    disabled: false,
    inputProps: {},
};

export default Component;