import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default styled.div`
    height: 100%;

    &.layout-slider {
        height: 100%;
        position: relative;

        .custom-slick-arrows {
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: -62px;
            font-size: 24px;

            &__left-arrow,
            &__right-arrow {
                cursor: pointer;
            }
        }
    }

    .slick-slider {
        position: relative;
        z-index: 9;

        .slick-list {
            overflow: inherit;
            overflow-x: clip;

            .slick-track {
                display: flex;
                justify-content: center;
                align-items: center;

                .slick-slide {
                    margin: 0 0.5em;
                }
            }
        }

        .slick-arrow {
            z-index: 999;

            &.slick-prev {
                left: 0;
            }

            &.slick-next {
                right: 0;
            }
        }

        .slick-dots {
            position: absolute;
            display: flex !important;
            justify-content: center;
            align-items: center;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%);
            width: fit-content;
            gap: 1.5em;

            li {
                width: 14px;
                height: 14px;
                border-radius: 14px;
                background-color: transparent;
                border: 1px solid ${vars.colorPrimary};
                margin: 0;

                .slider-dot {
                    width: 100%;
                    height: 100%;
                }

                @media (pointer: fine) {
                    &:hover {
                        border-color: ${vars.colorViolet};
                        background-color: ${vars.colorViolet};
                    }
                }

                &.slick-active {
                    background-color: ${vars.colorViolet};
                    border-color: ${vars.colorViolet};
                }
            }
        }

        .slick-prev:before,
        .slick-next:before {
            display: none;
        }

        @media all and (max-width: ${vars.mobileM}) {
            .slick-slider {
                .slick-list {
                    .slick-track {
                        .slick-slide {
                            opacity: 0.3;
                        }

                        .slick-active {
                            opacity: 1;
                        }
                    }
                }

                .slick-arrow {
                    width: 35px;
                    height: 35px;
                    color: white;

                    &.slick-prev {
                        left: 0;
                    }

                    &.slick-next {
                        right: 0;
                    }
                }
            }
        }

        @media all and (min-width: ${vars.mobileL}) {
            .slick-arrow {
                width: 45px;
                height: 45px;
                color: white;

                &.slick-prev {
                    transition: transform 200ms ease-in-out;
                    left: 10%;

                    &:before {
                        content: '<';
                        color: #000000;
                        font-size: 40px;
                    }

                    &:hover {
                        transform: translate(0, -50%) scale(1.2);
                    }
                }

                &.slick-next {
                    transition: transform 200ms ease-in-out;
                    right: 10%;

                    &:before {
                        content: '>';
                        color: #000000;
                        font-size: 40px;
                    }

                    &:hover {
                        transform: translate(0, -50%) scale(1.2);
                    }
                }
            }
        }
    }
`;
