import { FunctionComponent } from 'react';
import Select from 'react-select';
import classnames from 'classnames';

import { Option } from 'types/options';

import InputWrapper from 'components/layout/forms/InputWrapper';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutFormsSelect: FunctionComponent<Props> = ({ name, label, placeholder, value, onChange, disabled, style, styles, options, inputProps }) => {
    if(options.length > 0 && (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean')) {
        const selectedOption = options.find((option: Option<any>) => option.value === value);
        value = selectedOption || value;
    }

    return (
        <StyledComponent
            className={classnames(
                'layout-forms-select',
                [
                    `style-${style}`,
                    `name-${name}`,
                ],
                {
                    'disabled': Boolean(disabled),
                }
            )}
            style={styles}
        >
            <InputWrapper
                label={label}
                disabled={disabled}
            >
                <Select
                    name={name}
                    options={options}
                    value={value}
                    placeholder={placeholder}
                    onChange={(option: Option<any>) => {
                        onChange(option);
                    }}
                    isDisabled={disabled}
                    noOptionsMessage={() => 'Brak wyników'}
                    isClearable={true}
                    {...inputProps}
                />
            </InputWrapper>
        </StyledComponent>
    );
};

export default LayoutFormsSelect;