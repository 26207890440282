import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    position: relative;
    width: 100%;
    padding: 4em 0 0 0;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    min-height: 400px;

    .desktop {
        display: none;
    }

    @media all and (min-width: ${vars.desktopXS}) {
        .mobile {
            display: none;
        }

        .desktop {
            display: block;
        }
    }
`;
