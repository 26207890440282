const translations = {
    title: {
        type: 'text',
        defaultValue: 'Hero',
    },
    headline: {
        type: 'text',
        defaultValue: 'POSTAW NA ŚWIADOMY ROZWÓJ',
    },
    subheadline: {
        type: 'text',
        defaultValue:
            'Dołącz do największej polskiej platformy rozwoju osobistego. Odkryj wiedzę i narzędzia, których najbardziej potrzebujesz.',
    },
    searchInputPlaceholder: {
        type: 'text',
        defaultValue: 'Jakiego kierunku rozwoju szukasz?',
    },
    featureContainer: {
        type: 'text',
        defaultValue: {
            headline: 'Stań się zmianą, którą chcesz widzieć w świecie.',
            list: [
                {
                    title: 'RZETELNOŚĆ',
                    description: 'Tylko sprawdzone i profesjonalne szkolenia i warsztaty',
                },
                {
                    title: 'EFEKTYWNOŚĆ',
                    description: 'Inteligentny system dopasowania kursów do Twoich potrzeb',
                },
                {
                    title: 'SPOŁECZNOŚĆ',
                    description:
                        'Stań się częścią wielkiej społeczności ludzi rozwijających się - takich jak Ty!',
                },
            ],
        },
    },
};

export default translations;
