import ApiPage from 'types/api/Page';
import { ApiResources } from 'types/apiResources';
import { Endpoints } from 'types/endpoints';

import Page from 'models/Page';

import { withVariables } from 'utils/string';
import { request } from 'services/Api';

export interface SingleParams {
    slug: string;
}
export const single = async (params: SingleParams): Promise<Page> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicPage, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource: ApiPage = data && data[ApiResources.Page];

    if(!data || !resource) {
        return null;
    }

    return new Page(resource);
};
