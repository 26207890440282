import { FunctionComponent, useRef } from 'react';
import Slick from 'react-slick';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import arrowLeft from 'theme/icons/arrow-left.svg';
import arrowRight from 'theme/icons/arrow-right.svg';

import Image from 'components/layout/Image';
import SliderButton from 'components/layout/SliderButton';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutSlider: FunctionComponent<Props> = ({ children, sliderProps }) => {
    const slider = useRef(null);
    const childrenLength = (children as React.ReactNode[]).length;

    return (
        <StyledComponent className="layout-slider">
            <Slick
                ref={slider}
                adaptiveHeight={false}
                accessibility={true}
                arrows={false}
                dots={true}
                infinite={childrenLength > 1}
                speed={500}
                {...sliderProps}
                nextArrow={(
                    <SliderButton>
                        <Image
                            className="arrow-right"
                            src={arrowRight}
                            aria-label="Przesuń w prawo"
                            loading="lazy"
                        />
                    </SliderButton>
                )}
                prevArrow={!sliderProps?.onlyRightArrow ? (
                    <SliderButton>
                        <Image
                            className="arrow-left"
                            src={arrowLeft}
                            aria-label="Przesuń w lewo"
                            loading="lazy"
                        />
                    </SliderButton>
                ) : null}
                customPaging={() => (
                    <div className="slider-dot">
                        <div className="dot" />
                    </div>
                )}
            >
                {children}
            </Slick>
            {sliderProps?.arrowsAttachedToPagination && childrenLength > 1 && (
                <div
                    className="custom-slick-arrows"
                    style={{ gap: `${1 + (childrenLength * 1.6)}em` }}
                >
                    <div
                        className="custom-slick-arrows__left-arrow"
                        onClick={() => slider?.current?.slickPrev()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            style={{ color: 'white' }}
                        />
                    </div>
                    <div
                        className="custom-slick-arrows__right-arrow"
                        onClick={() => slider?.current?.slickNext()}
                    >
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            style={{ color: 'white' }}
                        />
                    </div>
                </div>
            )}
        </StyledComponent>
    );
};

export default LayoutSlider;
