import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.section`
    display: flex;
    flex-direction: column;

    .headline {
        color: ${vars.colorTextWhite};
        font-size: 3em;
        text-align: center;
        margin-bottom: 1em;
    }

    .course {
        width: 370px;
        overflow: hidden;
        &__image {
            &__label {
                left: 1.5em !important;
            }

            &__date {
                right: 0.5em !important;
            }
        }
    }

    @media all and (max-width: ${vars.mobileS}) {
        .course {
            width: 250px !important;
        }

        .layout-slider {
            .arrow-right {
                display: none !important;
            }

            .arrow-left {
                display: none !important;
            }
        }
    }
`;
