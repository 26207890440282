const translations = {
    headline: {
        type: 'text',
        defaultValue: 'OTWARTY DOSTĘP DO WIEDZY',
    },
    subHeadline: {
        type: 'text',
        defaultValue: 'Najciekawsze artykuły naszych autorów i ekspertów',
    },
    readMoreButton: {
        type: 'text',
        defaultValue: 'Czytaj więcej',
    },
    seeMorePostsButton: {
        type: 'text',
        defaultValue: 'Zobacz Więcej Postów',
    },
};

export default translations;
