import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';

import { Routes } from 'types/routes';

import Course from 'models/Course';

import SectionCourseTiles from 'components/content/sections/CourseTiles';
import SectionCourseTilesSlider from 'components/content/sections/CourseTilesSlider';
import LayoutContainer, { Sizes } from 'components/layout/LayoutContainer';
import useJoinCourseSchedule from 'hooks/useJoinCourseSchedule';
import useLikeCourse from 'hooks/useLikeCourse';

import StyledComponent from './styles';
import { Props } from './types';

const SectionCourses: FunctionComponent<Props> = ({ translations, courses }) => {
    const router = useRouter();
    const { likeCourse } = useLikeCourse();
    const { joinCourseSchedule } = useJoinCourseSchedule();

    const onLike = (course: Course) => {
        likeCourse(course);
    };

    const onJoin = (course: Course) => {
        joinCourseSchedule(course);
    };

    const onMoreCourses = (): Promise<Boolean> => router.push(Routes.PublicCourses);

    return (
        <StyledComponent className="section-courses">
            <LayoutContainer size={Sizes.Medium}>
                <div className="mobile">
                    <SectionCourseTilesSlider
                        courses={courses}
                        translations={translations}
                        visibleMoreCoursesButton
                        onMoreCourses={onMoreCourses}
                        onJoin={onJoin}
                        onLike={onLike}
                    />
                </div>
                <div className="desktop">
                    <SectionCourseTiles
                        courses={courses}
                        translations={translations}
                        onJoin={onJoin}
                        onLike={onLike}
                        visibleMoreCoursesButton
                        onMoreCourses={onMoreCourses}
                    />
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default SectionCourses;
