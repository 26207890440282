import blogPosts from './sections/blogPosts';
import courses from './sections/courses';
import cta from './sections/cta';
import hero from './sections/hero';
import newsletter from './sections/newsletter';

const translations = {
    page: {
        metaTitle: 'Strona główna',
        metaDescription: 'Strona główna',
    },
    sections: {
        hero,
        newsletter,
        courses,
        blogPosts,
        cta,
    },
};

export default translations;
