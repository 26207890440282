import { useMemo } from 'react';
import { NextPage } from 'next';

import { PageSection } from 'types/pages';
import { Routes } from 'types/routes';

import { withVariables } from 'utils/string';

import Head from 'components/layout/Head';
import PageContainer from 'components/layout/PageContainer';
import SectionsWrapper from 'components/layout/SectionsWrapper';
import WrapperPublic from 'components/wrappers/Public';

import SectionArticles from './sections/Articles';
import SectionCourses from './sections/Courses';
import SectionCta from './sections/Cta';
import SectionHero from './sections/Hero';
import SectionNewsletter from './sections/Newsletter';
import StyledComponent from './styles';
import { Props } from './types';

const PageHome: NextPage<Props> = ({ pageTranslations, courseCategories, courses, articles }) => {
    const sections: PageSection[] = useMemo(() => [{
        slug: 'hero',
        order: 1,
        isVisible: true,
        children: (slug, translations) => (
            <SectionHero
                key={slug}
                translations={translations}
                courseCategories={courseCategories}
            />
        ),
    }, {
        slug: 'courses',
        order: 2,
        isVisible: true,
        children: (slug, translations) => (
            <SectionCourses
                key={slug}
                courses={courses}
                translations={translations}
            />
        ),
    }, {
        slug: 'blogPosts',
        order: 3,
        isVisible: true,
        children: (slug, translations) => (
            <SectionArticles
                key={slug}
                articles={articles}
                translations={translations}
            />
        ),
    }, {
        slug: 'cta',
        order: 4,
        isVisible: true,
        children: (slug, translations) => (
            <SectionCta
                key={slug}
                translations={translations}
            />
        ),
    }, {
        slug: 'newsletter',
        order: 5,
        isVisible: true,
        children: (slug, translations) => (
            <SectionNewsletter
                key={slug}
                translations={translations}
            />
        ),
    }], []);

    return (
        <WrapperPublic>
            <StyledComponent className="page-home">
                <Head
                    title={pageTranslations?.page?.metaTitle}
                    description={pageTranslations?.page?.metaDescription}
                    canonical={withVariables(Routes.PublicHome)}
                />
                <PageContainer>
                    <SectionsWrapper
                        sections={sections}
                        localTranslations={pageTranslations?.sections}
                        enabledStates={pageTranslations?.sectionsEnabled}
                    />
                </PageContainer>
            </StyledComponent>
        </WrapperPublic>
    );
};

export default PageHome;
