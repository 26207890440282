import { FunctionComponent } from 'react';

import PointedArrowRightLight from 'theme/icons/pointed-arrow-right-light.svg';

import CourseTile from 'components/content/blocks/CourseTile';
import Button from 'components/layout/Button';
import { ButtonIconTypes, ButtonStyles, ButtonVariants } from 'components/layout/Button/types';
import Slider from 'components/layout/Slider';

import StyledComponent from './styles';
import { Props } from './types';

const SectionsCourseTilesSlider: FunctionComponent<Props> = ({
    courses,
    translations,
    onJoin,
    onLike,
    visibleMoreCoursesButton,
    onMoreCourses,
}) => {
    const config = {
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <StyledComponent className="sections-course-tiles-slider">
            {translations?.headline && (
                <div className="headline">
                    <h2>
                        {translations?.headline}
                    </h2>
                </div>
            )}
            <Slider sliderProps={config}>
                {Array.isArray(courses?.elements) && courses?.elements?.map((course) => (
                    <CourseTile
                        translations={{
                            ctaButton: translations?.courseCtaButton,
                            alreadyScheduledButton: translations?.alreadyScheduledButton,
                        }}
                        key={course.id}
                        course={course}
                        onJoin={onJoin}
                        onLike={onLike}
                    />
                ))}
            </Slider>
            {visibleMoreCoursesButton && (
                <div className="cta-more-courses">
                    <Button
                        style={ButtonStyles.Primary}
                        variant={ButtonVariants.Tertiary}
                        label="Zobacz Więcej"
                        icon={{
                            type: ButtonIconTypes.Image,
                            value: PointedArrowRightLight.src,
                            position: 'after',
                        }}
                        onClick={onMoreCourses}
                    />
                </div>
            )}
        </StyledComponent>
    );
};

export default SectionsCourseTilesSlider;
