import ApiPage from 'types/api/Page';

import Model from 'models/Model';
import PageSection from 'models/PageSection';

export default class Page implements Model {
    id: string;
    name: string;
    slug: string;
    metaTitle?: string;
    metaDescription?: string;
    sections?: PageSection[];

    constructor(data: ApiPage) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.metaTitle = data.metaTitle;
        this.metaDescription = data.metaDescription;
        this.sections = Array.isArray(data.sections)
            ?   data.sections.map(section => new PageSection(section))
            : [];
    }
}