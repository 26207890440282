import { FunctionComponent } from 'react';

import { Routes } from 'types/routes';

import Button from 'components/layout/Button';
import { ButtonStyles, ButtonVariants } from 'components/layout/Button/types';
import LayoutContainer, { Sizes } from 'components/layout/LayoutContainer';
import Link from 'components/layout/Link';

import StyledComponent from './styles';
import { Props } from './types';

const SectionCta: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent className="section-cta">
            <LayoutContainer size={Sizes.Medium}>
                <div className="headline">
                    <h2>
                        {translations?.headline}
                    </h2>
                </div>
                <div className="subHeadline">
                    <h3>
                        {translations?.subHeadline}
                    </h3>
                </div>
                <div className="cta-contact">
                    <Link
                        href={Routes.PublicContact}
                    >
                        <Button
                            className="gradient-180"
                            style={ButtonStyles.Primary}
                            variant={ButtonVariants.Primary}
                        >
                            {translations?.ctaButton}
                        </Button>
                    </Link>
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default SectionCta;
