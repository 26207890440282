const translations = {
    headline: {
        type: 'text',
        defaultValue: 'Skontaktuj się z nami!',
    },
    subHeadline: {
        type: 'text',
        defaultValue: '',
    },
    ctaButton: {
        type: 'text',
        defaultValue: 'Kontakt',
    },
};

export default translations;
