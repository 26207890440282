import { FunctionComponent } from 'react';

import NewsletterForm from 'components/content/forms/general/Newsletter';

import StyledComponent from './styles';
import { Props } from './types';

const PageHomeSectionNewsletter: FunctionComponent<Props> = ({ translations }) => {
    return (
        <StyledComponent className="page-home-section-newsletter">
            <NewsletterForm translations={{ ...translations }} />
        </StyledComponent>
    );
};

export default PageHomeSectionNewsletter;
