import ApiPageSection from 'types/api/PageSection';

import Model from 'models/Model';

export interface LocalFieldConfig {
    order?: number;
    defaultValue: any;
}

export default class PageSection implements Model {
    id: string;
    slug: string;
    enabled: boolean;
    values: Record<string, any>;

    constructor(data: ApiPageSection) {
        this.id = data.id;
        this.slug = data.slug;
        this.enabled = data.enabled;
        this.values = data.values;
    }
}