import ApiCourseCategory from 'types/api/CourseCategory';
import { ApiResources } from 'types/apiResources';
import { Endpoints, ListParamsDefault } from 'types/endpoints';
import ListCollection from 'types/redux/ListCollection';

import CourseCategory from 'models/CourseCategory';

import { withVariables } from 'utils/string';
import { request } from 'services/Api';

export interface ListParams extends ListParamsDefault {}
export interface ListResponse {
    [ApiResources.CourseCategories]: ListCollection<CourseCategory>;
}
export const list = async (params: ListParams): Promise<ListResponse> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicCourseCategories, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource: ListCollection<ApiCourseCategory> = data && data[ApiResources.CourseCategories];

    if(!data || !resource) {
        return null;
    }

    return {
        [ApiResources.CourseCategories]: {
            ...resource,
            elements: resource.elements.map(element => new CourseCategory(element)),
        },
    };
};


export interface SingleParams {
    slug: string;
}
export interface SingleResponse {
    [ApiResources.CourseCategory]: CourseCategory;
}
export const single = async (params: SingleParams): Promise<SingleResponse> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicCourseCategory, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource: ApiCourseCategory = data && data[ApiResources.CourseCategory];

    if(!data || !resource) {
        return null;
    }

    return {
        [ApiResources.CourseCategory]: new CourseCategory(resource),
    };
};
