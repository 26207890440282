import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

import { PageSlugs } from 'types/pages';
import ListCollection from 'types/redux/ListCollection';

import Article from 'models/Article';
import Course from 'models/Course';
import CourseCategory from 'models/CourseCategory';
import Page from 'models/Page';

import { LIST_MARKUP } from 'consts/api';
import { fillPage } from 'utils/translations';

import { list as listArticles } from 'requests/articles';
import { list as listCourseCategories } from 'requests/courseCategories';
import { list as listCourses } from 'requests/courses';
import { single as singlePage } from 'requests/pages';

import Component from './component';
import { Props } from './types';

export async function getServerSideProps(context: GetServerSidePropsContext): Promise<GetServerSidePropsResult<Props>> {
    let page: Page = null;
    let courseCategories: ListCollection<CourseCategory> = LIST_MARKUP;
    let courses: ListCollection<Course> = LIST_MARKUP;
    let articles: ListCollection<Article> = LIST_MARKUP;

    try {
        await Promise.all([
            singlePage({ slug: PageSlugs.Home }),
            listCourseCategories({ page: 1, perPage: 999 }),
            listCourses({ page: 1, perPage: 6 }),
            listArticles({ page: 1, perPage: 3 }),
        ]).then(responses => {
            page = responses[0];
            courseCategories = responses[1]?.courseCategories;
            courses = responses[2]?.courses;
            articles = responses[3]?.articles;
        });
    } catch (error) {
        console.error(error);
    }

    let pageTranslations = JSON.parse(JSON.stringify(require('locales/pl/pages/home')?.default));
    const fillValues = {};
    pageTranslations = fillPage(pageTranslations, page, fillValues);

    return {
        props: {
            pageTranslations,
            courseCategories: JSON.parse(JSON.stringify(courseCategories)),
            courses: JSON.parse(JSON.stringify(courses)),
            articles: JSON.parse(JSON.stringify(articles)),
        },
    };
}

export default Component;

